<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox5"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('powerBox5_heatDiagram','能耗热力图', getZEdata)}}</template>
      <template slot="aside">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in electricDic"
            :key="key"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-month-picker
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
      </template>
      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox5
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "carbonBox5",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      minDomain: 0,
      maxDomain: 0,
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
      
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "空调",
          name: "空调",
        },
        {
          code: "抽屉柜",
          name: "抽屉柜",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "",
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      colorList: ["rgb(198,198,198)", "rgba(198,198,198,.5)"],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
    //dashboard数据字典
    dashboardOptions() {
      return this.$store.state.dashboardOptions;
    },
    assetDic() {
      return this.dashboardOptions.assetDic;
    },
    electricDic() {
      let lang = sessionStorage.getItem("locale") || 'CH'
      
        if(lang=='EN'){
        
          for(let item of this.dashboardOptions.electricDic){
              if(item.name =='光伏'){
                  item.name = 'photovoltaic'
              }
              if(item.name =='动力'){
                  item.name = 'power'
              }
              if(item.name =='市电'){
                  item.name = 'electric supply'
              }
              if(item.name =='电机'){
                  item.name = 'electrical machinery'
              }
              if(item.name =='联络'){
                  item.name = 'liaison'
              }
          }
        }
      return this.dashboardOptions.electricDic;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    handleChange(value) {
      //console.log("handleChange", value, this.searchObj);
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    //每月报警预警数
    removeChar(str, char) {
      var index = str.indexOf(char);
      while (index != -1) {
        str = str.splice(index, 1);
        index = str.indexOf(char);
      }
      return str;
    },
    getData() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/heatmap";
      var data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        energyClass: this.searchObj.ITEM,
        // startDate:"2022-1-01 00:00:00",
        // endDate: "2022-12-10 23:59:59"
      };
      Object.assign(data, this.transformSearch());
      delete data.type;
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          if (res.data.code == 0) {
            this.detailInfo = {};
            let lang = sessionStorage.getItem("locale") || 'CH'
            let arr = []
            arr = res.data.data.charty
            if(lang=='EN'){
              arr = []
              for(let item of res.data.data.charty){
                var regex = new RegExp("点", "g");
                let str = ""
                str = item.replace(regex, '');
                console.log(str)
                arr.push(str)
              }
            }
            console.log(arr)
            var chart1 = {
              yCates: arr,
              xCates: res.data.data.chartx,
              data: res.data.data.data,
            };
            this.detailInfo.chart1 = chart1;
            this.maxDomain = res.data.data.max
              ? res.data.data.max
              : 0;
            this.minDomain = res.data.data.min
              ? res.data.data.min
              : 0;
            this.initEchart();
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      let vm = this;
      var data = this.detailInfo.chart1;
      let color1 =""
      let color2 =""
      

      if(this.searchObj.ITEM =='光伏'){
        this.colorList[0] = "rgba(12,154,17, 0.5)"
        this.colorList[1] = "rgb(121,235,125)"
      }else if(this.searchObj.ITEM =='市电'){
        this.colorList[0] = "rgb(198,198,198)"
        this.colorList[1] = "rgb(198,198,198,.5)"
      }else{
        this.colorList[0] = "rgb(64,169,255)"
        this.colorList[1] = "rgb(64,169,255,.5)"
      }
      
      var option = {
        animation: true,
        layoutAnimation: false,
        tooltip: {
          // position: "top",
          formatter: function (params) {
            // //console.log(params);
            let str = "";
            str += params.seriesName + "<br>";
            str += vm.LangHandler('date','日期') + ":" + params.name + "<br>";
            str += vm.LangHandler('time','时间') + ":" + params.value[1] + "点" + "<br>";
            str += vm.LangHandler('consumption','能耗') + ":" + params.value[2] + "<br>";
            return str;
          },
        },
        grid: {
          left: "5%",
          left: "0%",
          right: "1%",
          bottom: "8%",
          top: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: data.xCates,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          type: "category",
          name: "",
          data: data.yCates,
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: false,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
        },
        visualMap: {
          min: this.minDomain,
          max: this.maxDomain,
          calculable: true,
          orient: "horizontal",
          left: "center",
          bottom: "0%",
          color: this.colorList,
        },
        series: [
          {
            name: vm.commonLangHandler('powerBox5_heatDiagram','能耗热力图', this.getZEdata),
            type: "heatmap",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            data: data.data,
            label: {
              normal: {
                show: false,
                formatter: function (params) {
                  if (params.data[2] > 470) {
                    return "{a|" + params.data[2] + "}";
                  } else {
                    return "{b|" + params.data[2] + "}";
                  }
                },
                rich: {
                  a: {
                    color: "#fff",
                    fontSize: "8px",
                  },
                  b: {
                    color: "#000",
                    fontSize: "8px",
                  },
                },
              },
            },

            itemStyle: {
              normal: {
                borderColor: "rgba(255,255,255,.1)",
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      if (this.electricDic.length > 0) {
        this.searchObj.ITEM = this.electricDic[0].code
      }
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox5 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
